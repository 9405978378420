import "./App.css";
import Headshot from "../../assets/images/headshot.jpeg";

function App() {
  return (
    <div className="App">
      <img src={Headshot} alt="brnwll headshot lookin fly" />
      <br />
      <p>
        <b>wip:</b> coming soon
      </p>
    </div>
  );
}

export default App;
